// Custom Style
$hamburger-layer-color: #FFF;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-spacing: 3px;
$hamburger-layer-width: 16px;
$hamburger-layer-height: 3px;
$hamburger-layer-border-radius: 2px;
$hamburger-active-layer-color: #FFF;

// Hamburgers.scss
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~hamburgers/_sass/hamburgers/base";
