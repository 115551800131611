@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap&subset=latin-ext');

// Colors
$color-primary: #3442E3;
$color-secondary: #e5a2a2;
$color-tertiary: #00ffd4;
$color-white: #FFF;
$color-dark: #2D2D2D;
$color-dark-gray: #686a85;
$color-light-gray: #f2f6fa;

// Fonts
$font-primary: 'Montserrat', sans-serif;

// Breakpoints
$ground-zero: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;